.App {
  text-align: left;
  margin: 15px
}
body {
  margin-right: 15px
}
.Select {
  width: 200px;
  margin: 8px
}

.Header {
  padding-left:  8px;
  height: 25px;
}


td {
  text-align: center;
  line-height: 14px;
  padding: 5px;
}

th {
  /* height: 125px; */
  min-width: 45px; 
  font-weight: normal;
  text-align: center;
  padding: 5px;
  line-height: 14px;
}

.highth {
   height: 150px;
}

table {
  border-spacing: 0px;
  page-break-inside:auto
}

th span {
  display: block;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-weight: normal;
  max-width: 45px;
  text-align: center;
  padding-right: 1px;
  margin-top: 45px
  /*
    max-width contains the span and 
    must match the height of the th minus 
    any padding you want 
  */
}
/* .Wide {
  min-width: 70px; 
} */

.split {
  max-height: 8px;
  height: 8px;
}

/* rows */

/* .row1 {
  min-width: 75px; 
  font-weight: normal;
  text-align: center;
  padding: 7px
}
.row2 {
  width: 9px
}
.row3 {
  width: 9px
}
.row4 {
  width: 9px
}
.row5 {
  width: 9px
}
td {
  width: 25px
} */